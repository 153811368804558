import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        siteInfo:{},
        topNavList:[],
        cateNavList:[],
        isMobile: false,
        keyWord:''
    },
    getters: {
    },
    mutations: {
        SET_SITE_INFO(state, newVal){
            state.siteInfo = newVal;
        },
        SET_TOP_NAV(state, newVal){
            state.topNavList = newVal;
        },
        SET_CATE_NAV(state, newVal){
            state.cateNavList = newVal;
        },
        SET_MOBILE_VAL(state, newVal) {
            state.isMobile = newVal;
        },
        SET_KERWORD_VAL(state, newVal){
            state.keyWord = newVal;
        }
    },
    actions: {
    },
    modules: {
    }
})
