<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: "App",
    data() {
        return {
            screenWidth: 0,
        }
    },
    created(){
        translate.execute();
    },
    mounted() {
        const that = this;
        //监听屏幕宽度变化
        window.addEventListener("resize", function () {
            return (() => {
                that.getScreenWidth()
            })();
        });
        that.getScreenWidth()
    },
    methods: {
        getScreenWidth() {
            this.screenWidth = document.body.clientWidth;
            if (this.screenWidth <= 750) {
                this.$store.commit('SET_MOBILE_VAL', true);
            } else {
                this.$store.commit('SET_MOBILE_VAL', false);
            }
        }
    },
};
</script>
<style lang="scss">
:root {
    --main-color: #5a8caf;
    --second-color: #337ab7;
}

@import "../static/css/global.css";
@import "../static/css/iconfont.css";
</style>
