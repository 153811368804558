import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

/*全局组件*/
import BaseTitle from './components/BaseTitle'
Vue.component('BaseTitle', BaseTitle)


Vue.config.productionTip = false
Vue.prototype.$lang = 'chinese_simplified';
//网站地址
const origin = window.location.origin;
let hostUrl = '';
if (origin.indexOf('localhost') > -1) { //本地调取测试环境
    hostUrl = 'https://machinery.sxruanjian.cn';
} else {
    hostUrl = origin;
}
//全局ajax请求
Vue.prototype.globalRequest = function(param){
    return new Promise((resolve, reject)=>{
        $.ajax({
            url: `${hostUrl}/api/${param.api}`,
            type: param.method || "GET",
            data: param.data,
            timeout:5000,
            success(res){
                resolve(res)
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                reject(XMLHttpRequest)
                // alert(XMLHttpRequest.status);
                // alert(XMLHttpRequest.readyState);
                // alert(textStatus);
            },
        })
    })
}
Vue.prototype.$lang = 'chinese_simplified';
Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'current_lang') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                localStorage.setItem(k, val);

                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
